import ResizeObserver from 'resize-observer-polyfill'; // =============================== Const ===============================

var elementListeners = new Map();

function onResize(entities) {
  entities.forEach(function (entity) {
    var _elementListeners$get;

    var target = entity.target;
    (_elementListeners$get = elementListeners.get(target)) === null || _elementListeners$get === void 0 ? void 0 : _elementListeners$get.forEach(function (listener) {
      return listener(target);
    });
  });
} // Note: ResizeObserver polyfill not support option to measure border-box resize


var resizeObserver = new ResizeObserver(onResize); // Dev env only

export var _el = process.env.NODE_ENV !== 'production' ? elementListeners : null; // eslint-disable-line

export var _rs = process.env.NODE_ENV !== 'production' ? onResize : null; // eslint-disable-line
// ============================== Observe ==============================

export function observe(element, callback) {
  if (!elementListeners.has(element)) {
    elementListeners.set(element, new Set());
    resizeObserver.observe(element);
  }

  elementListeners.get(element).add(callback);
}
export function unobserve(element, callback) {
  if (elementListeners.has(element)) {
    elementListeners.get(element).delete(callback);

    if (!elementListeners.get(element).size) {
      resizeObserver.unobserve(element);
      elementListeners.delete(element);
    }
  }
}