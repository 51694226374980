import * as React from 'react';
import { MenuContext } from '../context/MenuContext';
export default function useDirectionStyle(level) {
  var _React$useContext = React.useContext(MenuContext),
      mode = _React$useContext.mode,
      rtl = _React$useContext.rtl,
      inlineIndent = _React$useContext.inlineIndent;

  if (mode !== 'inline') {
    return null;
  }

  var len = level;
  return rtl ? {
    paddingRight: len * inlineIndent
  } : {
    paddingLeft: len * inlineIndent
  };
}